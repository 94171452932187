import React, { useContext, useState } from "react";
import * as styles from "./Terrior.module.css";
import TenutaBanner from "../../Components/TenutaBanner/TenutaBanner";
import Header from "../../Components/Header/Header";
import BannerCard from "../../Components/BannerCard/BannerCard";
import terriorMap from "../../assets/images/terriorMap.png";
import LeftImageCard from "../../Components/LeftImageCard/LeftImageCard";
import Footer from "../../Components/Footer/Footer";
import { graphql } from "gatsby";
import { SEO } from "../../Components/Seo/seo";
import { languageContext } from "../../Components/Header/Header";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import HeroBanner from "../../Components/HeroBanner/HeroBanner";
import terriorBanner from "../../assets/images/terriorBanner.png"
import classNames from "classnames";

const Index = ({ data }) => {
  const llanguage = useContext(languageContext);
  const [isMapLocation, setMapLocation] = useState(false)
  const { language } = llanguage;
  const localizations = data.allStrapiTerroir.edges[0]?.node?.localizations || {};
  // local is localizations
  const {
    HeadingContent:localHeadingContent,
    BannerImage:localBannerImage,
    HeroImage:localHeroImage,
    ContentWithImage: localContentWithImage,
    DetailedInformation: localDetailedInformation,
    Information: localInformation
  } = localizations?.data[0]?.attributes || {};
  const {
    BannerImage,
    HeroImage,
    ContentWithImage,
    DetailedInformation,
    HeadingContent,
    Information,
    QuoteBox,
    ImageText
  } =
    language === "it"
      ? localizations?.data[0]?.attributes
      : data?.allStrapiTerroir?.edges[0]?.node || {}; 

  return (
<>
<div className="pageWrapper">
     <div className={styles.terriorSection}>
      <Header  heroBanner={true}/>

        {/* <HeroBanner
       bannerImg={HeroImage?.HeroImage[0]?.url}
       bannerTitle={HeadingContent?.Title}
        bannerDescription={HeadingContent?.Description}
       /> */}

<HeroBanner
       bannerImg={language === "it"
       ? localHeadingContent?.Image?.data?.attributes?.url
       : HeadingContent?.Image?.url}
       bannerDescription={HeadingContent?.Description}
       bannerTitle={HeadingContent?.Title}
       />

      <div className={styles.terriorLeftImg}>
        <LeftImageCard
          terrior={true}
          personImg={language === "it"
          ? localContentWithImage?.Image?.data?.attributes?.url
          : ContentWithImage.Image.url}
          paragraph_1={ContentWithImage?.Description}
        />
      </div>
      <div className={styles.imageBox}>
        <img className={styles.lastImage} src={language === "it"
       ? BannerImage?.HeroImage?.data[0]?.attributes?.url
       : BannerImage?.HeroImage[0]?.url}
        alt="icon" />
      </div>
      {/* <RightImageCard
                personImg={''}
                title={Information[0]?.Title}
                paragraph_1={Information[0]?.Text1}
                paragraph_2={Information[0]?.Text2}
            /> */}
      {/* Map Image card  */}
      <div className={`${styles.ctaSection}`}>
        <div className={styles.ctaBoxWrap}>
          <div className={`${styles.ctaDescription}`}>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <h1 className="animate__animated animate__fadeInUp">{Information[0]?.Title}</h1>
            </AnimationOnScroll>
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <p className="animate__animated animate__fadeInUp">{Information[0]?.Text1}</p>
            </AnimationOnScroll>
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <p className="animate__animated animate__fadeInUp">{Information[0]?.Text2}</p>
            </AnimationOnScroll>
          </div>
          <div className={styles.ctaImage}>
            <div>
              <img src={terriorMap} alt="" />
            </div>
            <h6 onClick={()=> setMapLocation(!isMapLocation)}>Mostra legenda <span>{isMapLocation ? "-" : "+"}</span> </h6>
           <div className={classNames(styles.ImgContents, isMapLocation && styles.ImgContentsShow)}>
              <div className={styles.LeftContent}>
                {ImageText?.slice(0, 6)?.map((item) => (
                  <div>
                    <div className={styles.ColorBox}></div>
                    <p>{item?.Description}</p>
                  </div>
                ))}
              </div>

              <div className={styles.RightContent}>
                {ImageText?.slice(6, 12)?.map((item) => (
                  <div>
                    <div className={styles.ColorBox}></div>
                    <p>{item?.Description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quote Box  */}
      <div className={styles.quoteBox}>
        <div className={styles.quoteContent}>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          <h4 className="animate__animated animate__fadeInUp">
            {QuoteBox?.Text1} <br /> <span>{QuoteBox?.Text2}</span>
          </h4>
          </AnimationOnScroll>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          <p className="animate__animated animate__fadeInUp">{QuoteBox?.Title}</p>
          </AnimationOnScroll>
        </div>
      </div>

      <LeftImageCard
        personImg={language === "it"
        ? localInformation[1]?.Image?.data?.attributes?.url
        : Information[1]?.Image?.url}
        title={Information[1]?.Title}
        paragraph_1={Information[1]?.Text1}
        paragraph_2={Information[1]?.Text2}
        // terrior={true}
      />

      <div className={styles.TerriorBanner}>
        <BannerCard
          terrior={true}
          title={DetailedInformation?.Title}
          bannerImg={language === "it"
          ? localDetailedInformation?.Image?.data?.attributes?.url
          : DetailedInformation?.Image?.url}
          paragraph_1={DetailedInformation?.Text1}
          paragraph_2={DetailedInformation?.Text2}
        />
      </div>
    </div>
   </div>
   <Footer />
</>
  );
};

export default Index;

export const query = graphql`
  query TerroirQuery {
    allStrapiTerroir {
      edges {
        node {

          BannerImage {
            HeroImage {
              url
            }
          }
          HeroImage {
            HeroImage {
              url
            }
          }
          QuoteBox {
            Image {
              url
            }
            Text1
            Text2
            Title
          }
          Information {
            Image {
              url
            }
            Text1
            Text2
            Title
          }
          HeadingContent {
            Description
            Title
            Image{
              url
            }
          }
          DetailedInformation {
            Text1
            Text2
            Title
            Image {
              url
            }
          }
          ContentWithImage {
            Description
            Image {
              url
            }
          }
          ImageText {
            Description
          }
          MetaKeyword
          MetaDescription
          localizations {
            data {
              attributes {
                ContentWithImage {
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  Description
                }
                DetailedInformation {
                  Text1
                  Text2
                  Title
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
                MetaKeyword
                MetaDescription
                HeadingContent {
                  Description
                  Title
                  Image{
                    data{
                      attributes{
                        url
                      }

                    }
                    
                  }
                }
                BannerImage {
                  HeroImage {
                    data{
                      attributes{
                        url
                      }
                    } 
                  }
                }

                ImageText {
                  Description
                }
                Information {
                  Text1
                  Text2
                  Title
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
                QuoteBox {
                  Text1
                  Text2
                  Title
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const localizations = data?.allStrapiTerroir?.edges[0]?.node?.localizations || {};
  const { MetaKeyword , MetaDescription } = localizations?.data[0]?.attributes
  return (
    <SEO
    title={MetaKeyword} description={MetaDescription} 
    />
  );
};




