// extracted by mini-css-extract-plugin
export var ColorBox = "Terrior-module--ColorBox--bb4c8";
export var ImgContents = "Terrior-module--ImgContents--2536b";
export var ImgContentsShow = "Terrior-module--ImgContentsShow--1d2db";
export var LeftContent = "Terrior-module--LeftContent--a1f7c";
export var RightContent = "Terrior-module--RightContent--1a90d";
export var TerriorBanner = "Terrior-module--TerriorBanner--54e38";
export var TerriorHeroSection = "Terrior-module--TerriorHeroSection--21626";
export var ctaBoxWrap = "Terrior-module--ctaBoxWrap--a94a6";
export var ctaDescription = "Terrior-module--ctaDescription--523b4";
export var ctaDescription_2 = "Terrior-module--ctaDescription_2--45c5a";
export var ctaImage = "Terrior-module--ctaImage--00d47";
export var ctaSection = "Terrior-module--ctaSection--7445b";
export var ctaSection_2 = "Terrior-module--ctaSection_2--6a609";
export var imageBox = "Terrior-module--imageBox--4a577";
export var lastImage = "Terrior-module--lastImage--3f38f";
export var linkButton = "Terrior-module--linkButton--0778a";
export var quoteBox = "Terrior-module--quoteBox--5b9d4";
export var quoteContent = "Terrior-module--quoteContent--933b7";
export var terriorLeftImg = "Terrior-module--terriorLeftImg--1970a";
export var terriorSection = "Terrior-module--terriorSection--fd61a";